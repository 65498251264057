import React, { useState, useEffect } from 'react';
import Header from "./Header"; 
import Footer from "./Footer"; 
import { GoogleOAuthProvider, GoogleLogin  } from '@react-oauth/google';
import './index.css';

const RatingsPage = () => {
const [user, setUser] = useState(null);
const [ratings, setRatings] = useState([]);
const [hasRated, setHasRated] = useState(false); 
const [showMenu, setShowMenu] = useState(false);
const [message, setMessage] = useState(''); 
const [comment, setComment] = useState('');
// Handle login success
const handleLoginSuccess = (response) => {
const { credential } = response;
const decoded = decodeJwtResponse(credential);
const userData = {
name: decoded.name,
email: decoded.email,
imageUrl: decoded.picture,
};
setUser(userData);
localStorage.setItem('user', JSON.stringify(userData));
localStorage.setItem('hasRated', false); 
localStorage.setItem('userEmail', 'erahidaj@gmail.com');

};

 // Decode Google JWT response
const decodeJwtResponse = (credential) => {
const base64Url = credential.split('.')[1];
const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
 return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
}).join(''));
 return JSON.parse(jsonPayload);
};

// Handle logout
const handleLogout = () => {
setUser(null);
localStorage.removeItem('user');
setShowMenu(false); 
setHasRated(false); 
};

const fetchRatings = () => {
fetch('https://hocompany.website/api/ratingsPage.php', {
method: 'GET',
headers: {
'Content-Type': 'application/json',
},
})
.then((response) => response.json())
.then((data) => {
 if (data.ratings) {
setRatings(data.ratings); 
 }
})
 .catch((error) => {
console.error('Error fetching ratings:', error);
});
};
// Submit rating
const submitRating = (ratingValue) => {
if (!user) {
setMessage('Please login to rate.');
 return;
 }

if (hasRated) {
setMessage('You have already rated.');
return;
 }

 const ratingData = {
email: user.email,
rating: ratingValue,
comment: comment, 
imageUrl: user.imageUrl,
};

// Send rating data to the PHP API
fetch('https://hocompany.website/api/ratingsPage.php', {
method: 'POST',
headers: {
 'Content-Type': 'application/json',
 },
body: JSON.stringify(ratingData),
 })
.then((response) => response.json())
.then((data) => {
 console.log(data);
if (data.status === 'success') {
setMessage(data.message);  
setRatings((prevRatings) => [
 ...prevRatings,
ratingData
]);
setHasRated(true);
localStorage.setItem('hasRated', true);
localStorage.setItem('ratings', JSON.stringify([...ratings, ratingData]));
} else {
setMessage(data.message); 
 }
 })
.catch((error) => {
console.error('Error submitting rating:', error);
 setMessage('There was an error submitting your rating. Please try again later.');
});
};

// Render stars for rating
const renderStars = (ratingValue) => {
return Array.from({ length: 5 }, (_, index) => (
 <span
key={index}
className={`star ${index < ratingValue ? 'filled' : ''}`}
 onClick={() => submitRating(index + 1)}
>
 ★
</span>
 ));
};

// Load user and ratings from local storage on mount
useEffect(() => {
const savedUser = JSON.parse(localStorage.getItem('user'));
const hasUserRated = localStorage.getItem('hasRated') === 'true';
if (savedUser) {
setUser(savedUser);
}
setHasRated(hasUserRated);
fetchRatings();
}, []);

useEffect(() => {
if (message) {
 const timer = setTimeout(() => {
setMessage('');
}, 3000); 

return () => clearTimeout(timer); 
}
}, [message]);

return (
<div>
 {/* Header Section */}
<Header/>
<GoogleOAuthProvider clientId="617496361150-6iahn4n7q6efjh53ditvi1th0l07gma2.apps.googleusercontent.com">
<div className="ratings-page">
{/* User Authentication */}
{user ? (
<div className="user-info">
<div className="profile-menu">
<img
src={user.imageUrl}
alt="User"
className="user-image"
onClick={() => setShowMenu(!showMenu)}
/>
{showMenu && (
<div className="menu-dropdown">
<p className="menu-item">{user.name}</p>
 <p className="menu-item" onClick={handleLogout}>Logout</p>
</div>
 )}
 </div>
</div>
 ) : (
<div className="google-login">
<GoogleLogin
 onSuccess={handleLoginSuccess}
 onError={() => console.log('Login Failed')}
 theme="filled_blue"  
 shape="circle"
 size='large' 
  />
</div>
)}
 <section>
 {/* Rating Section */}
 {user && !hasRated && (
 <div className="rating-section">
<h2>Rate Us</h2>
<div className="stars">
{renderStars(0)}
</div>
<div className='write'>
<textarea 
placeholder="Leave a comment..." 
 value={comment}
onChange={(e) => setComment(e.target.value)} 
/>
<button onClick={() => submitRating(5)}>Submit Rating</button>
</div>
</div>
)}

{!user && (
<div className="rating-section">
<h2>Your Rating</h2>
<div className="stars">
 {renderStars(0)}  
</div>
</div>
)}

{hasRated && (
<p>You have already rated us. Thank you!</p>
                    )}
{/* Show message */}
{message && <div className="message" style={{textAlign:'center', color:'red',fontSize:'20px'}}>{message}</div>}
{/* Display Customer Ratings */}
<div className="ratings-grid">
 <h2>Customer Ratings</h2>
 <div className="ratings-list">
{ratings.map((rating, index) => (
<div key={index} className="rating-item">
<img src={rating.imageUrl} alt={rating.email} className="rating-avatar" />
<div className="rating-info">
<p className="rating-email">{rating.email}</p>
<div className="stars">
{renderStars(rating.rating)} 
</div>
<p className="rating-comment">{rating.comment}</p>
</div>
</div>
))}
</div>
</div>
</section>
</div>
</GoogleOAuthProvider>
{/* Footer */}
<Footer/>
</div>
);
};

export default RatingsPage;